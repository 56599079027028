import style from './index.module.scss'
import template from '@styles/reusable.styles.module.scss';
import { ICards } from '@components/main-page/main-page.state';
import { CardWithImage } from '@components/shared/card-types/card-with-image';
import { ButtonPrimary } from '@components/shared/buttons/button-primary';
import star from '/public/main-images/preview-section/star.png'
import iconLine from '/public/main-images/preview-section/icon.png'
import Image from 'next/image';

interface IPreviewSectionProps {
    title: string;
    subtitle: string
    cards: ICards[];
    scrollIntoView?: () => void;
}


const PreviewSection = ({ title, subtitle, cards, scrollIntoView }: IPreviewSectionProps) => {
    return (
        <section className={`${template.section} ${template.section_light}`}>
            <div className={`${template.container} ${template.container_large} ${style.flex}`}>
                <div className={`${style.title__block}`}>
                    <div className={`${style.title}`}>
                        <div className={`${style.title_none}`}>
                            Building&nbsp;
                        </div>
                        <div className={`${style.title_green}`}>
                            {title}
                            <div className={`${style.title__star}`}>
                                <Image src={star} alt="star" sizes="56px"/>
                            </div>
                        </div>
                    </div>
                    <div className={`${style.subtitle}`}>
                        {subtitle}
                    </div>
                    <div className={`${style.button}`}>
                        <ButtonPrimary action={scrollIntoView} text={`Get a quote`} />
                    </div>
                </div>
                <div className={`${style.cards}`}>
                    {
                        cards.map((item: ICards, index: number) => {
                            return (
                                <div key={index} className={`${style.cards__item}`}>
                                    <CardWithImage iconHeight='150px' linkText={`Learn more`} title={item.title} text={item.subtitle} iconSrc={item.imgSrc} link={item.link} />
                                </div>
                            )
                        })
                    }
                </div>
                <div className={`${style.line}`}>
                    <Image src={iconLine} alt="line-image" sizes={"136px"}/>
                </div>
            </div>
        </section>
    )
}

export default PreviewSection;
