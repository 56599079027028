import style from './index.module.scss'
import Link from 'next/link'

interface IButtonPrimaryProps {
    text: string;
    action?: () => void | string
}

export const ButtonPrimary = ({text, action}:IButtonPrimaryProps) => {
    if(typeof action === 'string') {
        return (
            <Link href={action}>
                <a className={`${style.button}`}>
                    {text}
                </a>
            </Link>
        )
    } else {
        return (
            <button onClick={action} className={`${style.button}`}>
                 {text}
            </button>
        )
    }
}